const Upolicy  = () => {
    return ( 
        <>
                   <div className="navBar fixed-top">
          <nav className="white_box pe-3">
            <ul>
                <a href="/"><img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline" style={{"width":"10%","transform":"scale(1.2)"}}/></a> 
              <div className="h_over d-inline fw-bold pt-4" style={{"float":"right","letterSpacing":"4px"}}>
                <a href="/" className="text-decoration-none text-dark me-3"><span  style={{"backgroundColor":""}}>Home</span></a>
                <a href="/news" className="text-decoration-none text-dark me-3"><span>Event</span></a>
               <a href="/" className="text-decoration-none text-dark me-3"><span>Service</span></a>
               <a href="/policy" className="text-decoration-none text-dark me-3"><span>Policy</span></a>
                <a href='/' className="text-decoration-none text-dark"><span>Contact</span></a>
                <a href="mailto:iattech20@gmail.com" className="text-decoration-none mx-1" style={{'fontSize':'1rem'}}><button className="px-4 py-2 bg-success border border-none"><i class="fa fa-envelope fs-5 fw-bold text-light" aria-hidden="true"></i></button></a>
                <a href="tel:+18764031688" className="text-decoration-none me-2" style={{'fontSize':'1rem'}}><button className="px-4 py-2 bg-warning border border-none"><i class="fa fa-phone fs-5 fw-bold text-success" aria-hidden="true"></i></button></a>
              </div>
            </ul>
          </nav>
       </div>

       <div className="container-fluid white_box d-md-none" id="home">
       <a href="/"><img className="w-25  me-2" src="/images/company_lowgo.png" alt="company_lowgo." /></a><button className="me-2 px-3 py-1 bg-warning border border-none"><a href="tel:+18764031688"><i class="fa fa-phone me-2 fs-4 text-success" aria-hidden="true"></i></a></button><button className="px-3 py-1 bg-success border border-none"><a href="mailto:iattech20@gmail.com"><i class="fa fa-envelope fs-4 text-warning" aria-hidden="true"></i></a></button>
        <i class="fa fa-bars text-dark fs-1 float-end mt-4 me-4 fw-bold" aria-hidden="true" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
        </div>



< div class="offcanvas offcanvas-end w-50" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
<div className="offcanvas-header">
    <a href="/"><img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline" style={{"width":"50%", "float":"left"}}/></a>

    <button type="button" className="btn-close btn-danger" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body text-white" style={{ backgroundColor: "black", textAlign: "center" }}>
  <a href="/" style={{ textDecoration: "none" }}>
    <div className="w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-home" aria-hidden="true"></i> Home
    </div>
  </a>
  <a href="/news" style={{ textDecoration: "none" }}>
    <div className="w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-question-circle" aria-hidden="true"></i> Event
    </div>
  </a>
  <a href="/" style={{ textDecoration: "none" }}>
    <div className="w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-wifi" aria-hidden="true"></i> Service
    </div>
  </a>
  <a href="/policy" style={{ textDecoration: "none" }}>
    <div className="w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-shield" aria-hidden="true"></i> Policy
    </div>
  </a>
  <a href="/" style={{ textDecoration: "none" }}>
    <div className="w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-address-book-o" aria-hidden="true"></i> Contact
    </div>
  </a>
</div>
</div>


<div class="container formBox_1 Abox">
      <div className="text-center p-2 my-3">
      <p className="Cred">Policy</p>
      <h1 className="text-bold mb-5" style={{"lineHeight":"1px"}}>Terms and Conditions</h1>
      <p>Welcome to Electronics Technicians Training Limited (E.T.T.L.). By using our website and services, you agree to the following terms and conditions. Please read them carefully.</p>
      <h3>Our Services</h3>
      <p>E.T.T.L. provides solutions in telecommunications, engineering, consultancy, and training, including:</p>
      
       <ul className="text-start">
        <li>Wireless mesh networks</li><br />
        <li>Remote video surveillance</li><br />
        <li>Two-way radio systems</li><br />
        <li>Emergency alert systems</li><br />
         <li>Maritime, aeronautical, and satellite radio systems</li>
       </ul>
   <p>Our services are designed to meet modern ICT demands using advanced technology and are subject to modification or discontinuation without notice.</p>
    
  <h3> User Responsibilities</h3>
   <p>By accessing our website, you agree:</p>   
     <ul className="text-start">
        <li>To use it lawfully and responsibly.</li><br />
        <li>Not to misuse or harm the website or attempt unauthorized access to its resources.</li>
    </ul> 
      
    <h3>Intellectual Property</h3>
     <p>All content on this site is owned by E.T.T.L. or its licensors. You are prohibited from reproducing, distributing, or modifying our materials without prior written consent.</p>
      
     <h3>Product and Service Information</h3> 
      <p>We aim for accuracy in all product and service descriptions. However, E.T.T.L. reserves the right to correct inaccuracies or make changes at any time without notice.</p>
      
      <h3>Off-Grid and Rural Solutions</h3>
       <p>Our off-grid, low-power wireless mesh and energy systems are ideal for remote areas. The deployment of these systems may be influenced by environmental and regulatory conditions beyond our control.</p><br />
     
       <h1>Privacy Policy</h1>
        <p>We are committed to protecting your privacy in compliance with Jamaica’s Data Protection Act.</p>
       
        <ul className="text-start">
            <li>Data Collection: We may collect personal data, such as your name, contact details, and service preferences, for service provision and communication.</li>
            <li>Data Usage: Your personal information will be used solely for delivering services, improving our offerings, and fulfilling legal obligations.</li>
            <li>Data Sharing: We will not share your personal data with third parties without your consent, unless required by law.</li>
            <li>Data Security: We implement appropriate security measures to protect your data from unauthorized access or breaches.</li>
            <li>Your Rights: Under the Data Protection Act, you have the right to access, update, or request deletion of your personal data. Please contact us to exercise these rights.</li>
        </ul>

      <h3>Limitation of Liability</h3>
      <p>E.T.T.L. is not responsible for any direct or indirect damages arising from the use of our website or services. This includes issues related to service interruptions or inaccuracies in the information provided.</p>

     <h3>Changes to Terms</h3>
      <p>We reserve the right to update these terms at any time. Continued use of our website after changes implies your acceptance of the revised terms.</p>

       <h3>Contact Us</h3>
       <p>For any inquiries regarding these terms or our privacy practices, please contact us at <a href="mailto:iattech20@gmail.com">iattech20@gmail.com</a></p>

      </div>
      </div>


       {/*Footer Section*/}

       <div className="body-footer text-center text-white bg-dark" style={{"backgroundColor":""}}>
            <footer className="w-100 py-4 flex-shrink-0">
                <div className="container py-4">
                    <div className="row gy-4 gx-5">
                        <div className="col-lg-3 col-md-6">
                            <h5 className="h1 text-white">Contact Us</h5>
                            <ul className="list-unstyled">
                                <li>Tel: (876) 403-1688 / 307-5890</li>
                                <li>Watsapp: (876) 870-6462</li>
                                <li>Email: mail@ettlonline.com / iattech20@gmail.com</li>
                            </ul>  
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-3">Menu</h5>
                            <ul className="list-unstyled">
                                <li><a href="/">Home</a></li>
                                <li><a href="/">About</a></li>
                                <li><a href="/">Service</a></li>
                                <li><a href="/">Contact</a></li>
                                <li><a href="/signin">Login</a></li>

                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-3">Links</h5>
                            <ul className="list-unstyled text-dark" style={{"fontSize":"0.75rem"}}>
                                <li><a href="https://maxxgreentech.com/" target={'_blank'}>Maxxgreen Technologies</a></li>
                                <li><a href="https://oneplaneteducation.com/" target={'_blank'}>One Planet Education</a></li>
                                <li><a href="https://www.alchemistclubstudios.com/" target={'_blank'}>Alchemist Club Studios</a></li>
                                <li><a href="https://www.smartpaddock.com/" target={'_blank'}>Smart Paddock</a></li>
                                <li><a href="https://www.seeedstudio.com/" target={'_blank'}>Seeed Studio</a></li>
                                <li><a href="https://weatherxm.com/" target={'_blank'}>Weather XM</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                        <h3 className="h1 text-white">E.T.T.L</h3>
                            <img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline my-2" style={{"width":"40%","transform":"scale(1.2)"}}/>
                            <p className="small" style={{"color":"gold"}}>"Expert Solutions for Modern Communications."</p>
                            <p><i class="fa fa-twitter-square me-3" aria-hidden="true"></i> 
                          <a href=""  className="text-decoration-none text-white"><i class="fa fa-instagram me-3" aria-hidden="true"></i></a> 
                            <i class="fa fa-facebook-square me-3" aria-hidden="true"></i>
                            </p> 
                        </div>
                    </div>
                </div>
                <p className="small text-light mb-0">&copy; Copyrights. All rights reserved. <a className="text-primary" href="www.breezeexpressonline.com" target={'_blank'}>electronictechniciantraininglimited.com</a></p>
            </footer>
                </div>
        </>
     );
}
 
export default Upolicy;