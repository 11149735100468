// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';  // For Firebase Storage
import { getFirestore } from 'firebase/firestore';  // For Firestore
import { getAuth } from 'firebase/auth';  // For Firebase Auth


// Your Firebase configuration from the Firebase Console
const firebaseConfig = {
    apiKey: "AIzaSyDcBAJfaqjGtwOhFa646l3cEg_tvFd3Dsk",
    authDomain: "ettl-project.firebaseapp.com",
    projectId: "ettl-project",
    storageBucket: "ettl-project.appspot.com",
    messagingSenderId: "673162071012",
    appId: "1:673162071012:web:c338b23baf3187eca855f1",
    measurementId: "G-W39VD65PTE"
  };
// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize services
const storage = getStorage(app);
const db = getFirestore(app);  // This is Firestore
const auth = getAuth(app);

export { app, storage, db, auth };  // Exporting initialized services
