import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { addDoc, collection, getDocs } from 'firebase/firestore'; // Import Firestore
import { db, auth, storage } from '../api_component/firebase'; // Firestore reference
import { signOut } from 'firebase/auth';
import EditEvent from '../edit_event_component/edit_event';



const ImageUpload = () => {
  const [images, setImages] = useState([]); // Array of selected images
  const [imagePreviews, setImagePreviews] = useState([]); // Array for image previews
  const [uploadProgress, setUploadProgress] = useState([]); // Array for upload progress
  const [imageURLs, setImageURLs] = useState([]); // URLs of uploaded images
  const [title, setTitle] = useState(''); // Title input state
  const [description, setDescription] = useState(''); // Description input state
  const [mess, setMess] = useState([]); // Array of selected images


  const MAX_IMAGES = 4; // Maximum number of allowed uploads
  const MAX_EVENTS = 2; // Maximum number of allowed events

  // Fetch all images from Firebase Storage on component mount
  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = ref(storage, 'images/'); // Folder reference
      try {
        const res = await listAll(storageRef);
        const urls = await Promise.all(
          res.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            return { url: downloadURL, ref: itemRef }; // Return both URL and reference to delete later
          })
        );
        setImageURLs(urls);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    };

    fetchImages();
  }, []);

  // Handle image selection
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (imageURLs.length + selectedFiles.length > MAX_IMAGES) {
      setMess(`You can only upload a maximum of ${MAX_IMAGES} images.`);
      return;
    }

    const newImages = selectedFiles.map(file => URL.createObjectURL(file)); // Create object URLs for preview
    const newUploadProgress = new Array(selectedFiles.length).fill(0); // Initialize progress for each file
    setImages(prev => [...prev, ...selectedFiles]);
    setImagePreviews(prev => [...prev, ...newImages]);
    setUploadProgress(prev => [...prev, ...newUploadProgress]);
  };

  // Handle image upload to Firebase
  const handleUpload = async () => {
    if (images.length === 0) {
      setMess("Please select at least one image first");
      return;
    }

    const uploadTasks = images.map((image, index) => {
      const storageRef = ref(storage, `images/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => {
            const newProgress = [...prev];
            newProgress[index] = progress; // Update progress for this specific image
            return newProgress;
          });
        },
        (error) => {
          console.error("Upload failed:", error);
          setMess("Failed to upload image");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setMess("Images uploaded successfully!");
            setImageURLs(prevURLs => [...prevURLs, { url: downloadURL, ref: storageRef }]); // Add new image URL and reference
            setImages([]); // Reset images selection
            setImagePreviews([]); // Reset image previews
            setUploadProgress([]); // Reset progress bar
          });
        }
      );

      return uploadTask; // Return the upload task for any further handling if needed
    });

    await Promise.all(uploadTasks); // Wait for all uploads to finish
  };

  // Handle image deletion
  const handleDelete = async (imageRef, index) => {
    try {
      await deleteObject(imageRef); // Delete image from Firebase storage
      setImageURLs(prevURLs => prevURLs.filter((_, i) => i !== index)); // Remove image from state
      setMess("Image deleted successfully!");
    } catch (error) {
      console.error("Failed to delete image:", error);
      setMess("Failed to delete image");
    }
  };

  // Handle form submission and saving to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title === '' || description === '' || imageURLs.length === 0) {
      setMess('Please provide a title, description, and at least one image.');
      return;
    }

    try {
      // Check if there are already 2 events saved
      const eventsSnapshot = await getDocs(collection(db, 'events'));
      if (eventsSnapshot.size >= MAX_EVENTS) {
        setMess(`You can only save up to ${MAX_EVENTS} events.`);
        return;
      }

      // Save to Firestore
      await addDoc(collection(db, 'events'), {
        title,
        description,
      });
      setMess('Event saved successfully!');

      // Reset title and description but keep uploaded images
      setTitle('');
      setDescription('');
    } catch (error) {
      console.error('Error saving event:', error);
      setMess('Failed to save event');
    }
  };

  
  const handleLogout = async () => {
    await signOut(auth);
  }

  const [board, setBoard] = useState(true);
  const [editE, setEdite] = useState(false);

   
  const handleEdit = async () => {
     setEdite(true);
     setBoard(false);
  }

  const handleDash = async () => {
     setBoard(true);
     setEdite(false);

  }

  return (
    <div>
      <div className="container-fluid white_box">
        <div className="" id="home">
          <img className="favicon w-25 ms-1 me-2" src="/images/company_lowgo.png" alt="company_lowgo." />
          <button className='bg-warning text-light border border-none' onClick={handleLogout}>Logout</button>
          <i className="fa fa-bars text-dark fs-1 float-end mt-4 me-4 fw-bold" aria-hidden="true" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
        </div>
      </div>

      <div className="offcanvas offcanvas-end w-50" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <a href="/"><img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline favicon" style={{ "width": "50%", "float": "left" }} /></a>
          <button type="button" className="btn-close btn-danger" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body text-white" style={{ backgroundColor: "black", textAlign: "center" }}>
        <div className="w-100 p-3 text-white btn_box" style={{ fontSize: "20px" }} onClick={handleDash}>
        <i class="fa fa-user" aria-hidden="true"></i> Dashboard
            </div>
            <div className="w-100 p-3 text-white btn_box" style={{ fontSize: "20px" }} onClick={handleEdit}>
            <i class="fa fa-scissors" aria-hidden="true"></i> Edit Event
            </div>
            <div className="w-100 p-3 text-white btn_box" style={{ fontSize: "20px" }} onClick={handleLogout}>
              <i className="fa fa-lock" aria-hidden="true"></i> Logout
            </div>
        </div>
      </div>

      {editE && <EditEvent />}
      {board && <div className="container my-5 white_box py-2">
        <h2>Upload Images and Create Event</h2>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          multiple
          disabled={imageURLs.length >= MAX_IMAGES}
        />
        {imagePreviews.length > 0 && (
          <div>
            {imagePreviews.map((preview, index) => (
              <img
                key={index}
                className='img-fluid'
                src={preview}
                alt={`Image Preview ${index}`}
                style={{ width: '150px', marginTop: '10px', marginRight: '10px' }}
              />
            ))}
          </div>
        )}
        <button className='my-3' onClick={handleUpload} disabled={images.length === 0}>
          Upload Images
        </button>
        {uploadProgress.length > 0 && (
          <div>
            {uploadProgress.map((progress, index) => (
              <p key={index}>Upload Progress for Image {index + 1}: {Math.round(progress)}%</p>
            ))}
          </div>
        )}

        <h3>Uploaded Images ({imageURLs.length}/{MAX_IMAGES})</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '20px' }}>
          {imageURLs.map((imageObj, index) => (
            <div key={index} style={{ position: 'relative' }}>
              <img
                className='img-fluid'
                src={imageObj.url}
                alt={`Uploaded ${index}`}
                style={{ width: '150px' }}
              />
              <button
                onClick={() => handleDelete(imageObj.ref, index)}
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  cursor: 'pointer',
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>

        {imageURLs.length >= MAX_IMAGES && <p>You have reached the maximum number of uploads.</p>}

        {/* Form for title and description */}
        <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
          <div>
            <label className='me-2'>Title:</label>
            <input
              className='border border-dark'
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Event title"
              required
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <label>Description:</label>
            <textarea
              className='border border-dark'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Event description"
              required
              rows="4"
              style={{ width: '100%' }}
            />
          </div>
          <button className='bg-warning text-light border border-none' type="submit" style={{ marginTop: '20px' }}>
            Save Event
          </button>
          {mess && <p className='text-success'>{mess}</p>}
        </form>
      </div>}
    </div>
  );
};

export default ImageUpload;
