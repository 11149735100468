import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../api_component/firebase';

const EditEvent = () => {
    const [events, setEvents] = useState([]);
    const [editingEvent, setEditingEvent] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    
    useEffect(() => {
        const fetchEvents = async () => {
            const eventsCollection = collection(db, 'events');
            const eventSnapshot = await getDocs(eventsCollection);
            const eventList = eventSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEvents(eventList);
        };

        fetchEvents();
    }, []);

    const handleEditClick = (event) => {
        setEditingEvent(event);
        setTitle(event.title);
        setDescription(event.description);
    };

    const handleUpdate = async () => {
        if (editingEvent) {
            const eventRef = doc(db, 'events', editingEvent.id);
            await updateDoc(eventRef, {
                title,
                description,
                updatedAt: serverTimestamp()  // Automatically set updatedAt to the current server time
            });
            setEditingEvent(null);
            setTitle('');
            setDescription('');
            
            // Re-fetch events to update the list
            const eventsCollection = collection(db, 'events');
            const eventSnapshot = await getDocs(eventsCollection);
            const eventList = eventSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEvents(eventList);
        }
    };

    return (
        <div className='formBox_1 container my-5'>
            <h1 className='text-danger'>Edit Events</h1>
            {events.map((event) => (
                <div className='my-4' key={event.id}>
                    <div>
                        <h3>{event.title}</h3>
                        <div style={{ wordBreak: 'break-word', backgroundColor: '#ddd' }} className='border border-none p-3'>
                            {event.description}
                        </div>
                        <p><small>Last Updated: {event.updatedAt?.toDate().toLocaleString() || 'N/A'}</small></p>
                    </div>
                    <button className='border border-none bg-primary py-2 mt-3 text-light w-100' onClick={() => handleEditClick(event)}>
                        Edit
                    </button>
                </div>
            ))}

            {editingEvent && (
                <div className='container formBox_1 text-center p-2'>
                    <h2>Edit Event</h2>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Title"
                        className='w-100 border border-dark'
                    /> <br /><br />
                    <textarea
                        rows={5}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        className='w-100 border border-dark'
                    /> <br /><br />
                    <button className='border border-none bg-success text-light py-2 me-2' onClick={handleUpdate}>
                        Update Event
                    </button>
                    <button className='border border-none bg-danger text-light py-2' onClick={() => setEditingEvent(null)}>
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
};

export default EditEvent;
