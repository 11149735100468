import UReset from "../components/reset_component/usr_reset";

const ResetP = () => {
    return ( 
        <>
        <UReset />
        </>
     );
}
 
export default ResetP;