import UAbout from "../components/about_component/about";

const AboutP = () => {
    return ( 
        <>
        <UAbout />
        </>
     );
}
 
export default AboutP;


