import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ref, getDownloadURL, listAll } from 'firebase/storage';
import { storage } from '../api_component/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../api_component/firebase';
import { Timestamp } from 'firebase/firestore'; // Import Firestore Timestamp

const EventComp = () => {
  const [imageURLs, setImageURLs] = useState([]);
  const [evet, setEvet] = useState([]);

  // Fetch all images from Firebase Storage on component mount
  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = ref(storage, 'images/');
      try {
        const res = await listAll(storageRef);
        const urls = await Promise.all(
          res.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            return { url: downloadURL, ref: itemRef };
          })
        );
        setImageURLs(urls);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    };

    fetchImages();
  }, []);

  // Fetch all events from Firestore
  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, 'events');
      try {
        const querySnapshot = await getDocs(eventsCollection);
        const events = querySnapshot.docs.map(doc => {
          const data = doc.data();

          // Convert Firestore Timestamp to JavaScript Date and then to readable format
          const updatedAt = data.updatedAt instanceof Timestamp 
            ? data.updatedAt.toDate().toLocaleString()
            : 'No date available';

          return {
            id: doc.id,
            title: data.title,
            description: data.description,
            updatedAt, // Store the formatted date string
          };
        });
        setEvet(events);
      } catch (error) {
        console.error('Error fetching events: ', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <>
                           {/*Navbar Section*/}
    
     <div className="navBar fixed-top">
          <nav className="bg-white pe-3">
            <ul>
                <a href="/"><img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline" style={{"width":"10%","transform":"scale(1.2)"}}/></a> 
              <div className="h_over d-inline fw-bold pt-4" style={{"float":"right","letterSpacing":"4px"}}>
                <a href="/" className="text-decoration-none text-dark me-3"><span  style={{"backgroundColor":""}}>Home</span></a>
                <a href="/news" className="text-decoration-none text-dark me-3"><span>Event</span></a>
               <a href="/" className="text-decoration-none text-dark me-3"><span>Service</span></a>
               <a href="/policy" className="text-decoration-none text-dark me-3"><span>Policy</span></a>
                <a href='/' className="text-decoration-none text-dark"><span>Contact</span></a>
                <a href="mailto:iattech20@gmail.com" className="text-decoration-none mx-1" style={{'fontSize':'1rem'}}><button className="px-4 py-2 bg-success border border-none"><i class="fa fa-envelope fs-5 fw-bold text-light" aria-hidden="true"></i></button></a>
                <a href="tel:+18764031688" className="text-decoration-none me-2" style={{'fontSize':'1rem'}}><button className="px-4 py-2 bg-warning border border-none"><i class="fa fa-phone fs-5 fw-bold text-success" aria-hidden="true"></i></button></a>
              </div>
            </ul>
          </nav>
      </div>
 
      <div className="container-fluid white_box d-md-none" id="home">
       <a href="/"><img className="w-25  me-2" src="/images/company_lowgo.png" alt="company_lowgo." /></a><button className="me-2 px-3 py-1 bg-warning border border-none"><a href="tel:+18764031688"><i class="fa fa-phone me-2 fs-4 text-success" aria-hidden="true"></i></a></button><button className="px-3 py-1 bg-success border border-none"><a href="mailto:iattech20@gmail.com"><i class="fa fa-envelope fs-4 text-warning" aria-hidden="true"></i></a></button>
        <i class="fa fa-bars text-dark fs-1 float-end mt-4 me-4 fw-bold" aria-hidden="true" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
        </div>

        < div class="offcanvas offcanvas-end w-50" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
<div className="offcanvas-header">
    <a href="/"><img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline" style={{"width":"50%", "float":"left"}}/></a>

    <button type="button" className="btn-close btn-danger" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body text-white" style={{ backgroundColor: "black", textAlign: "center" }}>
  <a href="/" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-home" aria-hidden="true"></i> Home
    </div>
  </a>
  <a href="/news" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-question-circle" aria-hidden="true"></i> Event
    </div>
  </a>
  <a href="/" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-wifi" aria-hidden="true"></i> Service
    </div>
  </a>
  <a href="/policy" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-shield" aria-hidden="true"></i> Policy
    </div>
  </a>
  <a href="/" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-address-book-o" aria-hidden="true"></i> Contact
    </div>
  </a>
</div>
</div>

      {/* Event Section */}
      <div className='cont-pad' style={{"paddingTop":"70px"}}>
        <p className="Cred text-center">Events</p>
        <h1 className="text-bold text-center" style={{ "lineHeight": "1px" }}>News/Events</h1><br /><br />
        <div className="news_box container mb-5 white_box">
          <h1 className="text-start text-success">Latest Events <i className="fa fa-newspaper-o" aria-hidden="true"></i></h1>
          <div className="row mb-2 mt-3">
            {/* Display first two images for the latest event */}
            {imageURLs.slice(0, 2).map((imageObj, index) => (
              <div className="image-container col" key={index}>
                <img className="img-fluid w-100 ms-1 me-2" src={imageObj.url} alt={`latest_photo_${index}`} />
              </div>
            ))}
          </div>

          {/* Display latest event details */}
          {evet.slice(-1).map((eventObj, index) => (
            <div className="mb-5" key={index} style={{ wordBreak: "break-word" }}>
              <h2>{eventObj.title || 'Event Title'}</h2>
              <p className="fs-5">{eventObj.description || 'Event description here.'}</p>
              <p className="text-muted">updated at: {eventObj.updatedAt}</p> {/* Display event creation time */}
            </div>
          ))}

          <h1 className="text-start text-danger">Previous Events <i className="fa fa-newspaper-o" aria-hidden="true"></i></h1>
          <div className="row mb-5">
            {/* Display images for previous events */}
            {imageURLs.slice(2).map((imageObj, index) => (
              <div className="col image-container" key={index}>
                <img className="img-fluid w-100 ms-1 me-2" src={imageObj.url} alt={`previous_photo_${index}`} />
              </div>
            ))}
          </div>

          {/* Display previous event details */}
          {evet.slice(0, 1).map((eventObj, index) => (
            <div className="mb-5" key={index} style={{ wordBreak: "break-word" }}>
              <h2>{eventObj.title || 'Event Title'}</h2>
              <p className="fs-5">{eventObj.description || 'Event description here.'}</p>
              <p className="text-muted">updated at: {eventObj.updatedAt}</p> {/* Display event creation time */}
            </div>
          ))}
        </div>
      </div>

       {/*Footer Section*/}

       <div className="body-footer text-center text-white bg-dark" style={{"backgroundColor":""}}>
            <footer className="w-100 py-4 flex-shrink-0">
                <div className="container py-4">
                    <div className="row gy-4 gx-5">
                        <div className="col-lg-3 col-md-6">
                            <h5 className="h1 text-white">Contact Us</h5>
                            <ul className="list-unstyled">
                                <li>Tel: (876) 403-1688 / 307-5890</li>
                                <li>Watsapp: (876) 870-6462</li>
                                <li>Email: mail@ettlonline.com / iattech20@gmail.com</li>
                            </ul>  
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-3">Menu</h5>
                            <ul className="list-unstyled">
                                <li><a href="/">Home</a></li>
                                <li><a href="/">About</a></li>
                                <li><a href="/">Service</a></li>
                                <li><a href="/">Contact</a></li>
                                <Link className='Cgreen' to={"/signin"}>Login</Link>

                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-3">Links</h5>
                            <ul className="list-unstyled text-dark" style={{"fontSize":"0.75rem"}}>
                                <li><a href="https://maxxgreentech.com/" target={'_blank'}>Maxxgreen Technologies</a></li>
                                <li><a href="https://oneplaneteducation.com/" target={'_blank'}>One Planet Education</a></li>
                                <li><a href="https://www.alchemistclubstudios.com/" target={'_blank'}>Alchemist Club Studios</a></li>
                                <li><a href="https://www.smartpaddock.com/" target={'_blank'}>Smart Paddock</a></li>
                                <li><a href="https://www.seeedstudio.com/" target={'_blank'}>Seeed Studio</a></li>
                                <li><a href="https://weatherxm.com/" target={'_blank'}>Weather XM</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                        <h3 className="h1 text-white">E.T.T.L</h3>
                            <img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline my-2" style={{"width":"40%","transform":"scale(1.2)"}}/>
                            <p className="small" style={{"color":"gold"}}>"Expert Solutions for Modern Communications."</p>
                            <p><i class="fa fa-twitter-square me-3" aria-hidden="true"></i> 
                          <a href=""  className="text-decoration-none text-white"><i class="fa fa-instagram me-3" aria-hidden="true"></i></a> 
                            <i class="fa fa-facebook-square me-3" aria-hidden="true"></i>
                            </p> 
                        </div>
                    </div>
                </div>
                <p className="small text-light mb-0">&copy; Copyrights. All rights reserved. <a className="text-primary" href="www.breezeexpressonline.com" target={'_blank'}>electronictechniciantraininglimited.com</a></p>
            </footer>
                </div>
    </>
  );
};

export default EventComp;
