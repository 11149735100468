import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { motion } from "framer-motion"

const HomeComp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    phone: '',
    message: '', // Added message field
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Input validation
  const validateInput = () => {
    let newErrors = {};

    // Validate first name
    if (!formData.firstName) {
      newErrors.firstName = 'First Name is required';
    } else if (!/^[a-zA-Z\s]+$/.test(formData.firstName)) {
      newErrors.firstName = 'First Name can only contain letters';
    }


    // Validate email
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is not valid';
    }

    // Validate phone number
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^[0-9]{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }

    // Validate message
    if (!formData.message) {
      newErrors.message = 'Message is required';
    } else if (formData.message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters long';
    }

    return newErrors;
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const validationErrors = validateInput();

    if (Object.keys(validationErrors).length === 0) {
      // Sanitize the input data
      const sanitizedData = {
        firstName: DOMPurify.sanitize(formData.firstName),
        email: DOMPurify.sanitize(formData.email),
        phone: DOMPurify.sanitize(formData.phone),
        message: DOMPurify.sanitize(formData.message), // Sanitize message
      };

      console.log('Sanitized and validated data:', sanitizedData);
      setIsSubmitted(true);

      // Now, allow the form to be submitted to the action URL
      event.target.submit();
    } else {
      setErrors(validationErrors);
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
    return ( 
        <div>

                     {/*Navbar Section*/}
    
     <div className="navBar fixed-top">
          <nav className="bg-white pe-3">
            <ul>
                <a href="/"><img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline" style={{"width":"10%","transform":"scale(1.2)"}}/></a> 
              <div className="h_over d-inline fw-bold pt-4" style={{"float":"right","letterSpacing":"4px"}}>
                <a href="#" className="text-decoration-none text-dark me-3"><span  style={{"backgroundColor":""}}>Home</span></a>
                <a href="/news" className="text-decoration-none text-dark me-3"><span>Event</span></a>
               <a href="#service" className="text-decoration-none text-dark me-3"><span>Service</span></a>
               <a href="/policy" className="text-decoration-none text-dark me-3"><span>Policy</span></a>
                <a href='#contact' className="text-decoration-none text-dark"><span>Contact</span></a>
                <a href="mailto:iattech20@gmail.com" className="text-decoration-none mx-1" style={{'fontSize':'1rem'}}><button className="px-4 py-2 bg-success border border-none"><i class="fa fa-envelope fs-5 fw-bold text-light" aria-hidden="true"></i></button></a>
                <a href="tel:+18764031688" className="text-decoration-none me-2" style={{'fontSize':'1rem'}}><button className="px-4 py-2 bg-warning border border-none"><i class="fa fa-phone fs-5 fw-bold text-success" aria-hidden="true"></i></button></a>
              </div>
            </ul>
          </nav>
      </div>
 
      <div className="container-fluid white_box d-md-none" id="home">
       <a href="/"><img className="w-25  me-2" src="/images/company_lowgo.png" alt="company_lowgo." /></a><button className="me-2 px-3 py-1 bg-warning border border-none"><a href="tel:+18764031688"><i class="fa fa-phone me-2 fs-4 text-success" aria-hidden="true"></i></a></button><button className="px-3 py-1 bg-success border border-none"><a href="mailto:iattech20@gmail.com"><i class="fa fa-envelope fs-4 text-warning" aria-hidden="true"></i></a></button>
        <i class="fa fa-bars text-dark fs-1 float-end mt-4 me-4 fw-bold" aria-hidden="true" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
        </div>

                             {/*Hero Section*/}

  <div className="hero container-fluid pb-5" >
        <motion.div className="uptop text-center" style={{"wordBreak":"break-word",padding:"100px 1px 20px 1px"}}
        initial={{x:-50,opacity:0}}
        animate={{x:0,opacity:1.5}}
        transition={{delay:1.5,duration:1, stiffness:120}}
        >
         <h1 className="text-light">EMPOWERING</h1>
         <h2 className="text-warning">THROUGH TELECOMMUNICATION</h2>
         <p className="text-light fs-5">Delivering innovative networks communication.</p>
        <Link to={"/about"}><button className="green me-2 px-2 py-1 border border-none">Read More</button></Link> <a href="#contact"><button className="bg-warning text-light px-2 py-1 border border-none">Contact Us</button></a>
        </motion.div>


< div class="offcanvas offcanvas-end w-50" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
<div className="offcanvas-header">
    <a href="/"><img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline" style={{"width":"50%", "float":"left"}}/></a>

    <button type="button" className="btn-close btn-danger" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body text-white" style={{ backgroundColor: "black", textAlign: "center" }}>
  <a href="/" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-home" aria-hidden="true"></i> Home
    </div>
  </a>
  <a href="/news" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-question-circle" aria-hidden="true"></i> Event
    </div>
  </a>
  <a href="#service" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-wifi" aria-hidden="true"></i> Service
    </div>
  </a>
  <a href="/policy" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-shield" aria-hidden="true"></i> Policy
    </div>
  </a>
  <a href="#contact" style={{ textDecoration: "none" }}>
    <div className="btn_box w-100 p-3 text-white" style={{ fontSize: "20px" }}>
      <i class="fa fa-address-book-o" aria-hidden="true"></i> Contact
    </div>
  </a>
</div>
</div>
  </div>

                     {/*About Us Section <div class="col col d-none d-md-block">*/}
<div class="container text-center" id="about">
  <div class="row my-5">
<div class="col-sm">
      <div>
      <img className="w-100 ms-1 img-fluid" src="/images/ship.jpg" alt="ship_photo." />
      </div>
    </div>
    <div class="formBox_1 col">
      <div className="text-start p-2">
      <p className="Cred">About Us</p>
      <h1 className="text-bold mb-4" style={{"lineHeight":"1px"}}>Who Are We</h1>
      <div className='v_center'><p className='fs-5'>
      At E.T.T.L., we specialize in delivering comprehensive Telecommunications, Engineering, Consulting, and Training services, as well as Equipment Sales & Service.</p></div>
      <Link to={"/about"}><button className="bg-warning text-light border border-white me-2 px-2 py-1">Read More</button></Link>
      </div>
    </div>
  </div>
</div>                     
                               {/*Insustries served Section*/}
    
<div class="container-fluid mb-5 text-center">
<p className="Cred text-center">Industries</p>
      <h1 className="para_1 text-bold mb-4 text-center" style={{"lineHeight":"1px"}}>Industries We Serve</h1>
  <div class="row text-center">
    <div class="col-sm">
    <div className="pad_cont image-container-one">
    <img className="img-fluid" src="/images/photo_1.jpeg" alt="ship_photo." />
      </div>
      <h6>Government Agencies</h6>
    </div>
    <div class="col-sm">
    <div className="image-container-one">
     <img className="img-fluid" src="/images/photo_9.jpg" alt="ship_photo." />
      </div>
      <h6>Rural Areas</h6>
    </div>
    <div class="col-sm">
    <div className="image-container-one">
     <img className="img-fluid" src="/images/enterprise.jpg" alt="ship_photo." />
      </div>
      <h6>Businesses & Enterprises</h6>
    </div>
  </div><br />
 
  <div class="row text-center">
    <div class="col-sm">
    <div className="pad_cont image-container-one">
     <img className="img-fluid" src="/images/photo_3.jpg" alt="ship_photo." />
      </div>
      <h6>Agriculture</h6>
    </div>
    <div class="col-sm">
    <div className="image-container-one">
    <img className="img-fluid" src="/images/photo_6.jpg" alt="ship_photo." />
      </div>
      <h6>Emergency Telecommunications Services</h6>
    </div>
    <div class="col-sm">
    <div className="image-container-one">
   <img className="img-fluid" src="/images/photo_2.jpg" alt="ship_photo." />
      </div>
      <h6>Marine</h6>
    </div>
  </div>
</div>

                                  {/*Service Section*/}

<div class="container text-center mb-5" id="service">
  <div class="row">
  <p className="para Cred">Service</p>
      <h1 className="text-bold mb-5" style={{"lineHeight":"1px"}}>Our Services</h1>
    <div class="col-sm">
      <div className="formBox_1 p-3">
      <i class="fa fa-home fs-1 Cgreen" aria-hidden="true"></i>
      <h6>Consulting & Project Management</h6>
      <p>We offer comprehensive project management for unified communications, overseeing every detail from conception to completion. Whether it's for a rural community, a multi-tenant office, or an off-grid site, we ensure your systems are designed to meet the highest standards.</p>
      <a href="#contact"><button className="bg-warning text-light border border-white me-2 px-2 py-1">Contact Us</button></a>
      </div>
    </div>
    <div class="col-sm">
    <div className="formBox_1 p-3">
      <i class="fa fa-home fs-1 Cgreen" aria-hidden="true"></i>
      <h6>Wireless Communication System Design</h6>
      <p>Our experts specialize in designing and implementing commercial-grade wireless systems that are customized to fit your business’s needs. From infrastructure development to documentation for frequency licensing, we provide end-to-end solutions for radio and wireless communications.</p>
      <a href="#contact"><button className="bg-warning text-light border border-white me-2 px-2 py-1">Contact Us</button></a>
      </div>
    </div>
    <div class="col-sm">
    <div className="formBox_1 p-3">
      <i class="fa fa-home fs-1 Cgreen" aria-hidden="true"></i>
      <h6>Professional Installation</h6>
      <p>Our team ensures your communications equipment is installed with precision and to industry standards. From large-scale installations to small systems in specialty vehicles, we ensure reliable performance, durability, long-gevity and long-term functionality even in demanding and rural environments.</p>
      <a href="#contact"><button className="bg-warning text-light border border-white me-2 px-2 py-1">Contact Us</button></a>
      </div>
    </div>
  </div><br />
  <div class="row">
    <div class="col-sm">
      <div className="formBox_1 p-3">
      <i class="fa fa-home fs-1 Cgreen" aria-hidden="true"></i>
      <h6>Emergency Communications Systems</h6>
      <p>We design custom emergency communication solutions to ensure your organization stays connected. Our systems are reliable, and intuitive, offering layers of redundancy to keep you talking when it matters most.</p>
      <a href="#contact"><button className="bg-warning text-light border border-white me-2 px-2 py-1">Contact Us</button></a>
      </div>
    </div>
    <div class="col-sm">
    <div className="formBox_1 p-3">
      <i class="fa fa-home fs-1 Cgreen" aria-hidden="true"></i>
      <h6>Equipment Sales & Field Testing</h6>
      <p>We offer a wide range of telecommunications equipment, from satellite radios to two-way communication devices. Every piece of equipment is tested to ensure it meets performance expectations, especially in demanding environments.</p>
      <a href="#contact"><button className="bg-warning text-light border border-white me-2 px-2 py-1">Contact Us</button></a>
      </div>
    </div>
    <div class="col-sm">
    <div className="formBox_1 p-3">
      <i class="fa fa-home fs-1 Cgreen" aria-hidden="true"></i>
      <h6>Training & Support</h6>
      <p>We don’t just install systems; we ensure you know how to use them. Our training programs are tailored to your specific system, including quick-reference guides, on-site training for ease of use, ongoing support, and refresher courses.</p>
      <a href="#contact"><button className="bg-warning text-light border border-white me-2 px-2 py-1">Contact Us</button></a>
      </div>
    </div>
  </div>
</div>

                                       
                                      {/*Modern Solution Section*/}

                                      <div class="container text-center">
  <div class="row my-5">
<div class="col-sm">
      <div>
      <img className="w-100 ms-1 img-fluid" src="/images/machine.jpg" alt="ship_photo." />
      </div>
    </div>
    <div class="formBox_1 col">
      <div className="text-center p-2">
      <p className="Cred">Modern Communications</p>
      <h1 className="text-bold mb-5" style={{"lineHeight":"1px"}}>New Gen Technology</h1>
      <div className='v_center'><p className="fs-5">
      At Electronic technician Training Limited, we understand the complexities of modern communication systems and the unique needs of each client. Our team of telecommunications communications specialists bring a customer-centric approach to every project.</p></div>
      </div>
    </div>
  </div>
</div>   

                                      {/*Why choose us Section*/}

 <div class="container text-center mb-5 formBox_1 p-3">
 <p className="Cred">Reason To Choose</p>
      <h1 className="text-bold mb-4" style={{"lineHeight":"1px"}}>Why Choose Us</h1>
  <div class="row pt-2">
    <div class="col-sm">
      <div className="bg-warning py-1">
      <i class="fa fa-home fs-1 text-light" aria-hidden="true"></i>
      <h6 className="text-light">Tailored Effective & Manageable</h6>
      </div>
      <div>
        <p>We provide customized communications systems that align with your operational goals, whether for urban offices or remote locations.</p>
      </div>
    </div>
    <div class="col-sm">
    <div className="green py-1">
      <i class="fa fa-home fs-1 text-light" aria-hidden="true"></i>
      <h6 className="text-light">End-to-End Project Management</h6>
      </div>
      <div>
        <p>From initial consultation to installation and training, we handle all aspects of your communication infrastructure projects.</p>
      </div>
    </div>
    <div class="col-sm">
    <div className="bg-warning py-1">
      <i class="fa fa-home fs-1 text-light" aria-hidden="true"></i>
      <h6 className="text-light">Expertise in Emerging Technologies</h6>
      </div>
      <div>
        <p>With experience in wireless, satellite, and cellular technologies, we ensure seamless integration across platforms to keep you connected in any situation.</p>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-sm">
      <div className="green py-1">
      <i class="fa fa-home fs-1 text-light" aria-hidden="true"></i>
      <h6 className="text-light">Customer Satisfaction</h6>
      </div>
      <div>
        <p>Our goal is to simplify complex technologies, ensuring our clients understand and can maximize the benefits of their systems.</p>
      </div>
    </div>
    <div class="col-sm">
    <div className="green py-1">
      <i class="fa fa-home fs-1 text-light" aria-hidden="true"></i>
      <h6 className="text-light">Reliability</h6>
      </div>
      <div>
        <p>We deliver solutions that are durable, intuitive, and designed to perform under all circumstances, from daily operations to emergencies.
</p>
      </div>
    </div>
  </div>
</div>
                                         {/*Contact Us Section*/}
        
    <div className="forma px-2 mx-2 container_fluid mb-5" id="contact">
      <div className="py-5 text-center">
        <p className="fw-bold mb-0" style={{ color: 'gold' }}>Contact</p>
        <h1 className="fw-bold">Get In Touch</h1>
        <div className="">
          <form onSubmit={handleSubmit} className="formBox_1 p-3" style={{ margin: 'auto' }} action="https://formsubmit.co/iattech20@gmail.com" method="POST">
            <div className="mb-3" style={{ textAlign: 'center' }}>
              <img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline" style={{ width: '30%', transform: 'scale(1.2)', margin: 'auto' }} />
            </div>

            <div className="mb-3">
              <input
                type="text"
                name="firstName"
                className="form-control"
                placeholder="Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
            </div>

            <div className="mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <small className="text-danger">{errors.email}</small>}
            </div>

            <div className="mb-3">
              <input
                type="number"
                name="phone"
                className="form-control"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
              />
              {errors.phone && <small className="text-danger">{errors.phone}</small>}
            </div>

            <div className="mb-3">
              <textarea
                name="message"
                className="form-control"
                placeholder="Your Message"
                rows="4"
                value={formData.message}
                onChange={handleInputChange}
              />
              {errors.message && <small className="text-danger">{errors.message}</small>}
            </div>

            <input type="hidden" name="_next" value="https://ettlonline.com/" />
            <input type="hidden" name="_template" value="box" />
            <input type="hidden" name="_captcha" value="false" />
            
            <button type="submit" className="bg-warning text-light px-2 py-1 border border-none">
              Send Message
            </button>
            {isSubmitted && <p className="text-success mt-3">Message sent successfully!</p>}
          </form>
        </div>
      </div>
    </div>


                                         {/*Footer Section*/}

        <div className="body-footer text-center text-white bg-dark" style={{"backgroundColor":""}}>
            <footer className="w-100 py-4 flex-shrink-0">
                <div className="container py-4">
                    <div className="row gy-4 gx-5">
                        <div className="col-lg-3 col-md-6">
                            <h5 className="h1 text-white">Contact Us</h5>
                            <ul className="list-unstyled">
                                <li>Tel: (876) 403-1688 / 307-5890</li>
                                <li>Watsapp: (876) 870-6462</li>
                                <li>Email: mail@ettlonline.com / iattech20@gmail.com</li>
                            </ul>  
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-3">Menu</h5>
                            <ul className="list-unstyled">
                                <li><a href="#">Home</a></li>
                                <li><a href="#about">About</a></li>
                                <li><a href="#service">Service</a></li>
                                <li><a href="#contact">Contact</a></li>
                                <li><Link to={"/signin"}>Login</Link></li>

                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-3">Links</h5>
                            <ul className="list-unstyled text-dark" style={{"fontSize":"0.75rem"}}>
                                <li><a href="https://maxxgreentech.com/" target={'_blank'}>Maxxgreen Technologies</a></li>
                                <li><a href="https://oneplaneteducation.com/" target={'_blank'}>One Planet Education</a></li>
                                <li><a href="https://www.alchemistclubstudios.com/" target={'_blank'}>Alchemist Club Studios</a></li>
                                <li><a href="https://www.smartpaddock.com/" target={'_blank'}>Smart Paddock</a></li>
                                <li><a href="https://www.seeedstudio.com/" target={'_blank'}>Seeed Studio</a></li>
                                <li><a href="https://weatherxm.com/" target={'_blank'}>Weather XM</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                        <h3 className="h1 text-white">E.T.T.L</h3>
                            <img src="/images/company_lowgo.png" alt="logo" className="img-fluid d-inline my-2" style={{"width":"40%","transform":"scale(1.2)"}}/>
                            <p className="small" style={{"color":"gold"}}>"Expert Solutions for Modern Communications."</p>
                            <p><i class="fa fa-twitter-square me-3" aria-hidden="true"></i> 
                          <a href=""  className="text-decoration-none text-white"><i class="fa fa-instagram me-3" aria-hidden="true"></i></a> 
                            <i class="fa fa-facebook-square me-3" aria-hidden="true"></i>
                            </p> 
                        </div>
                    </div>
                </div>
                <p className="small text-light mb-0">&copy; Copyrights. All rights reserved. <a className="text-primary" href="www.breezeexpressonline.com" target={'_blank'}>electronictechniciantraininglimited.com</a></p>
            </footer>
                </div>

        </div>
     );
}
 
export default HomeComp;