import ImageUpload from "../components/upload_component/usr_upload";

const UploadP = () => {
    return ( 
       <>
       <ImageUpload/>
       </>
     );
}
 
export default UploadP;