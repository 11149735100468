import Registery from "../components/register_component/usr_register";

const RegisterP = () => {
    return ( 
        <>
        <Registery />
        </>
     );
}
 
export default RegisterP;




