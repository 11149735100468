import Upolicy from "../components/policy_component/policy";

const PolicyP = () => {
    return ( 
        <>
        <Upolicy />
        </>
     );
}
 
export default PolicyP;