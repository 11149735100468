import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeP from './page/home_page';
import UploadP from './page/upload_page';
import EventP from './page/event_page';
import LoginP from './page/login_page';
import RegisterP from './page/register_page';
import ResetP from './page/reset_page';
import AboutP from './page/about_page';
import PolicyP from './page/policy_page';
import PrivateRoute from './components/private_route_component/PrivateRoute';


function App() {
  return (
    <Router>
 <div>
  <Routes>
 <Route path="/" element={<HomeP />} />
 <Route path="/news" element={<EventP />} />
 <Route path="/signin" element={<LoginP />} />
 <Route path="/signup" element={<RegisterP />} />
 <Route path="/reset" element={<ResetP />} />
 <Route path="/about" element={<AboutP />} />
 <Route path="/policy" element={<PolicyP />} />
 <Route 
            path="/admin" 
            element={
              <PrivateRoute requiredRole="admin">
                <UploadP />
              </PrivateRoute>
            } 
          />

          {/* Catch-all route */}
  <Route path="*" element={<HomeP />} /> 
 </Routes>
 </div>
 </Router>
  );
}

export default App;
