import Ulogin from "../components/admin_component/usr_admin";

const LoginP = () => {
    return ( 
        <>
        <Ulogin />
        </>
     );
}
 
export default LoginP;