import HomeComp from "../components/home_component/home";

const HomeP = () => {
    return ( 
        <>
        <HomeComp/>
        </>
     );
}
 
export default HomeP;