import EventComp from "../components/event_component/event";

const EventP = () => {
    return ( 
        <>
        <EventComp />
        </>
     );
}
 
export default EventP;